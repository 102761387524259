<template>
  <el-dialog
    :close-on-click-modal="false"
    title="审核"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="100px">
      <el-form-item style="margin-bottom: 20px" label="状态" prop="is_check">
        <el-select
            v-model="is_audit"
            placeholder="请选择状态"
            style="width: 100%"
            size="small"
        >
          <el-option label="待审核" :value="0" />
          <el-option label="已通过" :value="1" />
          <el-option label="未通过" :value="2" />
        </el-select>
      </el-form-item>
      <div v-if="is_audit == 2">
        <el-form-item style="margin-bottom: 20px"  label="未通过原因" >
          <el-input v-model="reason" type="textarea" />
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {apiGoodsAudit} from "@/request/api";

  export default {
    name: 'AdminEdit',
    data() {
      return {
        is_audit: 0,
        dialogFormVisible: false,
        reason:'',
        ids:[]
      }
    },
    watch:{
      is_audit(val) {
        if (val == 2) {
          this.reason = '';
        }
      }
    },
    created() {},
    methods: {
      showEdit(ids) {
        this.is_audit = 0
        this.reason = ''
        this.ids = ids
        this.dialogFormVisible = true
      },
      close() {
        this.dialogFormVisible = false
      },
      save() {
        apiGoodsAudit({is_audit:this.is_audit,ids:this.ids,reason:this.reason}).then(res => {
          if(res.code == 200){
            this.$message.success(res.message)
            this.$emit('refresh')
            this.close()
          }
        })
      }
    },
  }
</script>
